#footer{
    background-color: #363636;
    margin-top: 80px;
    padding: 30px 0;
    &>.container{
        display: flex;
        justify-content: space-between;
        @include breakpoint(max-width 760px){
            display: block;
        }
    }
}

.footer-first{
    .logo{
        margin-bottom: 40px;
        @include breakpoint(max-width 760px){
            margin-bottom: 20px;
            img{
                margin: 0 auto;
            }
        }
    }
    .menu{
        list-style: none;
        @include breakpoint(max-width 760px){
            text-align: center;
            margin-bottom: 20px;
        }
        a{
            color: #cccccc;
            text-decoration: none;
            font-weight: 300;
            font-size: 14px;
            line-height: 28px;
        }
    }
}

.footer-second{
    @include breakpoint(max-width 760px){
        margin-bottom: 20px;
    }
}

.footer-three{
    @include breakpoint(max-width 760px){
        width: 250px;
        margin: 0 auto 20px;
    }
}

.footer-bottom{
    margin-top: 50px;
    font-size: 12px;
    color: #cccccc;
    font-weight: 300;
    @include breakpoint(max-width 760px){
        margin-top: 0;
    }
    .container{
        position: relative;
    }
    .copy{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
}