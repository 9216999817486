@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin container{ 
    width: 1200px;
    margin: 0 auto;
    @include breakpoint(max-width 1200px){
        padding-left: 10px;
        padding-right: 10px;
        width: 960px;
    }
    @include breakpoint(max-width 960px){
        width: 760px;
    }
    @include breakpoint(max-width 760px){
        width: 100%;
    }
}