.home{
    .content-main{
        padding-top: 40px;
    }
}

.front-slider{
    box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
    .slick-slide{
        margin: 0 5px;
    }
    .view-content{
        padding: 15px 40px 30px;
        width: 1510px;
        margin: 0 auto;
        @include breakpoint (max-width 1510px){
            width: 1200px;
        }
        @include breakpoint (max-width 1200px){
            width: 960px;
        }
        @include breakpoint (max-width 960px){
            width: 690px;
        }
        @include breakpoint (max-width 760px){
            width: 320px;
        }
    }
    .views-row{
        position: relative;
        &:hover{
            .block{
                height: 100%;
                .title{
                    margin-bottom: 20px;
                    &:after{ 
                        display: block;
                    }
                } 
                .txt{
                    display: block;
                    overflow: hidden;
                    max-height: 96px;
                    @include breakpoint (max-width 760px){
                        display: none;
                    }
                }
                .btn{
                    display: inline-block;
                }    
            }
        }
    }
    .block{
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, .6);
        padding: 36px 26px;
        color: #fff;
        font-weight: 300;
        @include breakpoint (max-width 760px){
            padding: 30px 20px;
        }
        .title{
            font-size: 18px;
            font-weight: 400;
            &:after{ 
                display: none;
                margin-top: 20px;
                content: '';
                height: 4px;
                width: 56px;
                background-color: #fff;
            }
        }
        .txt{
            display: none;
            margin-bottom: 20px;
        }
        .btn{
            display: none;
        }
    }
}

.content-main{
    &>.container{
        display: flex;
        flex-wrap: wrap;
    }
    .sidebar-left{
        width: 200px;
        margin-right: 15px;
        @include breakpoint(max-width 1200px){
            display: none;
        }
    }
    .content-content{
        width: 670px;
        margin-right: 15px;
        @include breakpoint(max-width 1200px){
            width: 630px;
            margin-right: 10px;
        }
        @include breakpoint(max-width 960px){
            margin: 0 auto;
        }
    }
    .sidebar-right{
        width: 300px;
        @include breakpoint(max-width 960px){
            margin: 100px auto 0;
        }
    }
}

.random-article{
    &>.title{
        font-weight: 300;
        font-size: 20px;
        &:after{
            content: '';
            display: block;
            width: 50px;
            height: 5px;
            background-color: $green;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
    .views-row{
        width: 200px;
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-bottom: 10px;
        position: relative;
        &:last-child{
            margin-bottom: 0;
        }
        &:hover{
            .block{
                height: 100%;
                .title{
                    &:after{
                        display: block;
                    }
                }
                .txt{
                    display: block;
                }
                .more{
                    display: block;
                }
            }
        }
        .block{
            position: absolute;
            z-index: 1;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            padding: 15px 20px;
            color: #fff;
            font-weight: 300;
            .title{
                font-size: 14px;
                font-weight: 400;
                &:after{
                    display: none;
                    margin-top: 8px;
                    margin-bottom: 12px; 
                    content: '';
                    height: 2px;
                    width: 30px;
                    background-color: #fff;
                }
            }
            .txt{
                display: none;
                font-size: 12px;
                overflow: hidden;
                height: 76px;
            }
            .more{
                display: none;
                margin-top: 10px;
                font-size: 12px;
                color: $orande;
                height: auto;
                line-height: initial;
                padding: 0;
                border-radius: 0;
                background-color: transparent;
                background-image: url(../images/arrow-more-or.png);
                background-position: 90px center;
            }
        }
    }
}

.index-content{
    .view-content{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        @include breakpoint(max-width 760px){
            display: block;
        }
        .misha_loadmore{
            padding: 0 50px;
            position: absolute;
            bottom: -65px;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
    .views-row{
        width: 327px;
        margin-bottom: 16px;
        border: 1px solid #ebebeb;
        position: relative;
        background-color: #fff;
        padding-bottom: 20px;
        @include breakpoint(max-width 1200px){
            width: 310px;
            margin-bottom: 10px;
        }
        @include breakpoint(max-width 760px){
            width: 300px;
            margin: 0 auto 10px;
        }
        @include breakpoint(min-width 760px){
            &.full{
                width: 100%;
                padding-bottom: 0;
                @include clearfix;
                box-shadow: 4px 4px 15px -4px rgba(0,0,0,0.1);
                .field-img{
                    height: 200px;
                    width: 265px;
                    margin-bottom: 0;
                    float: left;
                    margin-right: 25px;
                    &:before{
                        display: none;
                    }
                }
                .title{
                    padding: 15px 25px 0 0;
                    position: relative;
                    margin-bottom: 15px;
                    height: 63px;
                    overflow: hidden;
                    &:before{
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: '';
                        background-color: $green;
                        width: 50px;
                        height: 3px;
                        display: block;
                    }
                }
                .txt{
                    height: 62px;
                    overflow: hidden;
                    padding: 0;
                    padding-right: 10px;
                    @include breakpoint(max-width 1200px){
                        padding-right: 30px;
                    }
                }
            }     
        }
        .new-post{
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            text-transform: uppercase;
            color: #fff;
            font-weight: 500;
            background-color: #ffa800;
            padding: 5px 8px 4px;
        }
        .field-img{
            width: 100%;
            height: 170px;
            background-size: cover;
            background-position: left top;
            position: relative;
            margin-bottom: 20px;
            &:before{
                position: absolute;
                bottom: -3px;
                left: 26px;
                content: '';
                background-color: $green;
                width: 50px;
                height: 3px;
                display: block;
            }
        }
        .title{
            font-size: 18px;
            line-height: 24px;
            height: 72px;
            overflow: hidden;
            margin-bottom: 20px;
            padding: 0 30px;
            @include breakpoint(max-width 760px){
                padding: 0 15px;
            }
        }
        .txt{
            font-size: 14px;
            color: #787878;
            line-height: 22px;
            padding: 0 30px;
            margin-bottom: 15px;
            height: 130px;
            overflow: hidden;
            @include breakpoint(max-width 760px){
                padding: 0 15px;
            }
        }
        .bottom{
            padding: 0 30px;
            height: 30px;
            @include clearfix;
            @include breakpoint(max-width 760px){
                padding: 0 15px;
            }
        }
        .more{
            font-size: 14px;
            height: 30px;
            line-height: 30px;
            background-position: 80px center;
            float: left;
        }
        .share{
            display: inline-block;
            float: right;
        }
        .share-block{
            display: none;
            float: right;
            position: relative;
            top: 2px;
        }
    }
}

.post-template-default{
    .content-content{
        .info-single{
            display: flex;
            align-items: center;
            .date{
                font-size: 14px;
                color: #9b9b9b;
            }
            .count-views{
                font-size: 14px;
                color: #9b9b9b;
                margin-left: auto;
                margin-right: 20px;
                background: url(../images/views.png) no-repeat left center;
                padding-left: 30px;
            }
            .share-block-wrap{
                height: 30px;
                .share{
                    display: inline-block;
                } 
                .share-block{
                    display: none;
                }
            }
        }
        .single-img{
            margin-bottom: 35px;
            img{
                margin: 0 auto;
            }
        }
        p{
            margin-bottom: 15px;
            font-size: 14px;
            color: #3e3e3e;
            line-height: 24px;
        }
        h2{
            margin-bottom: 15px;
            font-size: 18px;
            font-weight: 700;
        }
        .wp-block-image{
            .aligncenter{
                position: relative;
                figcaption{
                    margin: 0;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    color: #fff;
                    font-size: 14px;
                    padding: 7px 5px 8px;
                    background-color: rgba(0, 0, 0, .6);
                }
            }
        }
    }
}

