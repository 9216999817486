.header-first{
    background-color: #fff;
    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        @include breakpoint(max-width 1200px){
            padding-left: 10px;
            padding-right: 10px;
        }
        @include breakpoint(max-width 760px){
            display: block;
        }
    }
    .search{
        width: 280px;
        @include breakpoint(max-width 760px){
            margin: 0 auto 12px;
        }
    }
    .logo{
        width: 220px;
        @include breakpoint(max-width 960px){
            margin: 0 10px;
        }
        @include breakpoint(max-width 760px){
            margin: 0 auto 12px;
        } 
    }
    .podpiska{
        width: 280px;
        @include breakpoint(max-width 760px){
            margin: 0 auto;
        }
    }
}

.header-second{
    background-color: #f5f5f5;
    box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
    .category-top{
        @include breakpoint(max-width 960px){
            min-height: 58px;
            position: relative;
        }
        #burger{
            display: none;
            cursor: pointer;
            height: 26px;
            width: 38px;  
            position: absolute;
            top: 17px;
            right: 0;
            @include breakpoint(max-width 960px){
                display: block;
            }
            span{
                width: 38px;
                display: block;
                height: 2px;
                position: absolute;
                left: 0; 
                background-color: #000;
                &:first-child{
                    top: 0;
                }
                &:nth-child(2){
                    top: 12px;
                }
                &:last-child{
                    bottom: 0;
                }
            }
        }
        ul{
            @include clearfix;
            list-style: none;
            @include breakpoint(max-width 960px){
                padding-top: 58px;
                display: none;
            }
        }
        li{
            float: left;
            @include breakpoint(max-width 960px){
                float: none;
                text-align: center;
            }
        }
        a{
            display: block;
            text-decoration: none;
            padding: 0 18px;
            height: 58px;
            line-height: 60px;
            color: $color;
            font-weight: 300;
            @include breakpoint (max-width 1200px){
                font-size: 14px;
                padding: 0 4px;
            }
            &:hover, &.active{
                background-color: $green;
                color: #fff;
            }
            img{
                display: inline-block;
                position: relative;
                bottom: -3px;
            }
        }
    }
}

.searchform{
    position: relative;
    label{
        display: none;
    }
    #s{
        font-weight: 300;
        color: #b8b8b8;
        font-size: 15px;
        border: 1px solid #959595;
        width: 200px;
        height: 44px;
        padding: 0 45px 0 25px;
        outline: none;
        border-radius: 50px 0 0 50px;
    }
    #searchsubmit{
        position: absolute;
        top: 0;
        right: 3px;
        color: #fff;
        border: none;
        font-weight: 300;
        height: 44px;
        width: 94px;
        outline: none;
        font-size: 16px;
        border-radius: 50px;
        cursor: pointer;
        background-color: $green;
    }
}

.podpiska{
    text-align: center;
    .title{
        color: #4e2312;
        margin-bottom: 15px;
    }
    .btn{
        font-size: 18px;
        background-image: url(../images/podpiska.png);
        background-repeat: no-repeat;
        background-position: 30px center;
        padding-left: 58px;
    }
}