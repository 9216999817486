@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&subset=cyrillic,cyrillic-ext');

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $color;
    background-color: #f7f7f7;
}

img{
    height: auto;
    max-width: 100%;
    display: block;
}

.btn{
    outline: none;
    cursor: pointer;
    border: none;
    background-color: $green;
    height: 50px;
    line-height: 48px;
    padding: 0 32px;
    font-weight: 300;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    border-radius: 50px;
    &.more{
        height: 40px;
        line-height: 40px;
        padding: 0 40px 0 24px;
        background-image: url(../images/arrow-more.png);
        background-repeat: no-repeat;
        background-position: 144px center;
    }
}

.slick-slide{
    outline: none;
}

.slick-arrow{
    font-size: 0;
    color: transparent;
    width: 32px;
    height: 60px;
    &:before{
        display: none;
    }
    &.slick-prev{
        background: url(../images/arrow-left.jpg);
        left: 0;
    }
    &.slick-next{
        background: url(../images/arrow-right.jpg);
        right: 0;
    }
}

.breadcrumbs{
    padding: 20px 0;
    .container{
        div{
            display: inline-block;
            color: #707070;
            font-size: 12px;
            font-weight: 300;
            &.arrow{
                position: relative;
                top: 3px;
                margin: 0 10px;
            }
            a{
                color: $green;
                text-decoration: none;
            }
        }   
    }
}

h1.page-title{
    font-size: 30px;
    font-weight: 700;
    color: #4e2312;
    margin-bottom: 20px;
}

.share{
    background: url(../images/podel.png) no-repeat left center;
    padding-left: 36px;
    text-decoration: none;
    color: #b5b5b5;
    font-size: 12px;
    font-weight: 300;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    text-transform: uppercase; 
}

.form{
    text-align: center;
    .title{
        color: #fff;
        font-weight: 300;
        line-height: 26px;
        &:after{
            content: '';
            display: block;
            margin: 18px auto 22px;
            width: 50px;
            height: 2px;
            background-color: #fff;
        }
    }
    .main{
        margin-bottom: 22px;
    }
    input{
        font-size: 14px;
        color: #989898;
        border: 1px solid #989898;
        border-radius: 50px;
        height: 40px;
        width: 200px;
        outline: none;
        background: none;
        padding: 0 25px;
        margin: 0 2px;
        @include breakpoint(max-width 960px){
            &:first-child{
                margin-bottom: 3px;
            }
        }
    }
    button{
        height: 40px;
        line-height: 40px;
        padding-left: 60px;
        padding-right: 60px;
        font-size: 16px;
    }
}

.modal-wrapper.styled .modal{
    background-color: #363636; 
    width: 460px;
    @include breakpoint(max-width 760px){
        width: 320px;
    }
}

.modal-wrapper.styled .modal .close-modal{
    color: #fff;
}