@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&subset=cyrillic,cyrillic-ext");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #151515;
  background-color: #f7f7f7;
}

img {
  height: auto;
  max-width: 100%;
  display: block;
}

.btn {
  outline: none;
  cursor: pointer;
  border: none;
  background-color: #026e1e;
  height: 50px;
  line-height: 48px;
  padding: 0 32px;
  font-weight: 300;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  border-radius: 50px;
}

.btn.more {
  height: 40px;
  line-height: 40px;
  padding: 0 40px 0 24px;
  background-image: url(../images/arrow-more.png);
  background-repeat: no-repeat;
  background-position: 144px center;
}

.slick-slide {
  outline: none;
}

.slick-arrow {
  font-size: 0;
  color: transparent;
  width: 32px;
  height: 60px;
}

.slick-arrow:before {
  display: none;
}

.slick-arrow.slick-prev {
  background: url(../images/arrow-left.jpg);
  left: 0;
}

.slick-arrow.slick-next {
  background: url(../images/arrow-right.jpg);
  right: 0;
}

.breadcrumbs {
  padding: 20px 0;
}

.breadcrumbs .container div {
  display: inline-block;
  color: #707070;
  font-size: 12px;
  font-weight: 300;
}

.breadcrumbs .container div.arrow {
  position: relative;
  top: 3px;
  margin: 0 10px;
}

.breadcrumbs .container div a {
  color: #026e1e;
  text-decoration: none;
}

h1.page-title {
  font-size: 30px;
  font-weight: 700;
  color: #4e2312;
  margin-bottom: 20px;
}

.share {
  background: url(../images/podel.png) no-repeat left center;
  padding-left: 36px;
  text-decoration: none;
  color: #b5b5b5;
  font-size: 12px;
  font-weight: 300;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  text-transform: uppercase;
}

.form {
  text-align: center;
}

.form .title {
  color: #fff;
  font-weight: 300;
  line-height: 26px;
}

.form .title:after {
  content: '';
  display: block;
  margin: 18px auto 22px;
  width: 50px;
  height: 2px;
  background-color: #fff;
}

.form .main {
  margin-bottom: 22px;
}

.form input {
  font-size: 14px;
  color: #989898;
  border: 1px solid #989898;
  border-radius: 50px;
  height: 40px;
  width: 200px;
  outline: none;
  background: none;
  padding: 0 25px;
  margin: 0 2px;
}

@media (max-width: 960px) {
  .form input:first-child {
    margin-bottom: 3px;
  }
}

.form button {
  height: 40px;
  line-height: 40px;
  padding-left: 60px;
  padding-right: 60px;
  font-size: 16px;
}

.modal-wrapper.styled .modal {
  background-color: #363636;
  width: 460px;
}

@media (max-width: 760px) {
  .modal-wrapper.styled .modal {
    width: 320px;
  }
}

.modal-wrapper.styled .modal .close-modal {
  color: #fff;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
    width: 960px;
  }
}

@media (max-width: 960px) {
  .container {
    width: 760px;
  }
}

@media (max-width: 760px) {
  .container {
    width: 100%;
  }
}

.header-first {
  background-color: #fff;
}

.header-first .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

@media (max-width: 1200px) {
  .header-first .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 760px) {
  .header-first .container {
    display: block;
  }
}

.header-first .search {
  width: 280px;
}

@media (max-width: 760px) {
  .header-first .search {
    margin: 0 auto 12px;
  }
}

.header-first .logo {
  width: 220px;
}

@media (max-width: 960px) {
  .header-first .logo {
    margin: 0 10px;
  }
}

@media (max-width: 760px) {
  .header-first .logo {
    margin: 0 auto 12px;
  }
}

.header-first .podpiska {
  width: 280px;
}

@media (max-width: 760px) {
  .header-first .podpiska {
    margin: 0 auto;
  }
}

.header-second {
  background-color: #f5f5f5;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 960px) {
  .header-second .category-top {
    min-height: 58px;
    position: relative;
  }
}

.header-second .category-top #burger {
  display: none;
  cursor: pointer;
  height: 26px;
  width: 38px;
  position: absolute;
  top: 17px;
  right: 0;
}

@media (max-width: 960px) {
  .header-second .category-top #burger {
    display: block;
  }
}

.header-second .category-top #burger span {
  width: 38px;
  display: block;
  height: 2px;
  position: absolute;
  left: 0;
  background-color: #000;
}

.header-second .category-top #burger span:first-child {
  top: 0;
}

.header-second .category-top #burger span:nth-child(2) {
  top: 12px;
}

.header-second .category-top #burger span:last-child {
  bottom: 0;
}

.header-second .category-top ul {
  list-style: none;
}

.header-second .category-top ul:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 960px) {
  .header-second .category-top ul {
    padding-top: 58px;
    display: none;
  }
}

.header-second .category-top li {
  float: left;
}

@media (max-width: 960px) {
  .header-second .category-top li {
    float: none;
    text-align: center;
  }
}

.header-second .category-top a {
  display: block;
  text-decoration: none;
  padding: 0 18px;
  height: 58px;
  line-height: 60px;
  color: #151515;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .header-second .category-top a {
    font-size: 14px;
    padding: 0 4px;
  }
}

.header-second .category-top a:hover, .header-second .category-top a.active {
  background-color: #026e1e;
  color: #fff;
}

.header-second .category-top a img {
  display: inline-block;
  position: relative;
  bottom: -3px;
}

.searchform {
  position: relative;
}

.searchform label {
  display: none;
}

.searchform #s {
  font-weight: 300;
  color: #b8b8b8;
  font-size: 15px;
  border: 1px solid #959595;
  width: 200px;
  height: 44px;
  padding: 0 45px 0 25px;
  outline: none;
  border-radius: 50px 0 0 50px;
}

.searchform #searchsubmit {
  position: absolute;
  top: 0;
  right: 3px;
  color: #fff;
  border: none;
  font-weight: 300;
  height: 44px;
  width: 94px;
  outline: none;
  font-size: 16px;
  border-radius: 50px;
  cursor: pointer;
  background-color: #026e1e;
}

.podpiska {
  text-align: center;
}

.podpiska .title {
  color: #4e2312;
  margin-bottom: 15px;
}

.podpiska .btn {
  font-size: 18px;
  background-image: url(../images/podpiska.png);
  background-repeat: no-repeat;
  background-position: 30px center;
  padding-left: 58px;
}

.home .content-main {
  padding-top: 40px;
}

.front-slider {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
}

.front-slider .slick-slide {
  margin: 0 5px;
}

.front-slider .view-content {
  padding: 15px 40px 30px;
  width: 1510px;
  margin: 0 auto;
}

@media (max-width: 1510px) {
  .front-slider .view-content {
    width: 1200px;
  }
}

@media (max-width: 1200px) {
  .front-slider .view-content {
    width: 960px;
  }
}

@media (max-width: 960px) {
  .front-slider .view-content {
    width: 690px;
  }
}

@media (max-width: 760px) {
  .front-slider .view-content {
    width: 320px;
  }
}

.front-slider .views-row {
  position: relative;
}

.front-slider .views-row:hover .block {
  height: 100%;
}

.front-slider .views-row:hover .block .title {
  margin-bottom: 20px;
}

.front-slider .views-row:hover .block .title:after {
  display: block;
}

.front-slider .views-row:hover .block .txt {
  display: block;
  overflow: hidden;
  max-height: 96px;
}

@media (max-width: 760px) {
  .front-slider .views-row:hover .block .txt {
    display: none;
  }
}

.front-slider .views-row:hover .block .btn {
  display: inline-block;
}

.front-slider .block {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 36px 26px;
  color: #fff;
  font-weight: 300;
}

@media (max-width: 760px) {
  .front-slider .block {
    padding: 30px 20px;
  }
}

.front-slider .block .title {
  font-size: 18px;
  font-weight: 400;
}

.front-slider .block .title:after {
  display: none;
  margin-top: 20px;
  content: '';
  height: 4px;
  width: 56px;
  background-color: #fff;
}

.front-slider .block .txt {
  display: none;
  margin-bottom: 20px;
}

.front-slider .block .btn {
  display: none;
}

.content-main > .container {
  display: flex;
  flex-wrap: wrap;
}

.content-main .sidebar-left {
  width: 200px;
  margin-right: 15px;
}

@media (max-width: 1200px) {
  .content-main .sidebar-left {
    display: none;
  }
}

.content-main .content-content {
  width: 670px;
  margin-right: 15px;
}

@media (max-width: 1200px) {
  .content-main .content-content {
    width: 630px;
    margin-right: 10px;
  }
}

@media (max-width: 960px) {
  .content-main .content-content {
    margin: 0 auto;
  }
}

.content-main .sidebar-right {
  width: 300px;
}

@media (max-width: 960px) {
  .content-main .sidebar-right {
    margin: 100px auto 0;
  }
}

.random-article > .title {
  font-weight: 300;
  font-size: 20px;
}

.random-article > .title:after {
  content: '';
  display: block;
  width: 50px;
  height: 5px;
  background-color: #026e1e;
  margin-top: 10px;
  margin-bottom: 20px;
}

.random-article .views-row {
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
  position: relative;
}

.random-article .views-row:last-child {
  margin-bottom: 0;
}

.random-article .views-row:hover .block {
  height: 100%;
}

.random-article .views-row:hover .block .title:after {
  display: block;
}

.random-article .views-row:hover .block .txt {
  display: block;
}

.random-article .views-row:hover .block .more {
  display: block;
}

.random-article .views-row .block {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 15px 20px;
  color: #fff;
  font-weight: 300;
}

.random-article .views-row .block .title {
  font-size: 14px;
  font-weight: 400;
}

.random-article .views-row .block .title:after {
  display: none;
  margin-top: 8px;
  margin-bottom: 12px;
  content: '';
  height: 2px;
  width: 30px;
  background-color: #fff;
}

.random-article .views-row .block .txt {
  display: none;
  font-size: 12px;
  overflow: hidden;
  height: 76px;
}

.random-article .views-row .block .more {
  display: none;
  margin-top: 10px;
  font-size: 12px;
  color: #ffa800;
  height: auto;
  line-height: initial;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  background-image: url(../images/arrow-more-or.png);
  background-position: 90px center;
}

.index-content .view-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

@media (max-width: 760px) {
  .index-content .view-content {
    display: block;
  }
}

.index-content .view-content .misha_loadmore {
  padding: 0 50px;
  position: absolute;
  bottom: -65px;
  left: 50%;
  transform: translate(-50%, 0);
}

.index-content .views-row {
  width: 327px;
  margin-bottom: 16px;
  border: 1px solid #ebebeb;
  position: relative;
  background-color: #fff;
  padding-bottom: 20px;
}

@media (max-width: 1200px) {
  .index-content .views-row {
    width: 310px;
    margin-bottom: 10px;
  }
}

@media (max-width: 760px) {
  .index-content .views-row {
    width: 300px;
    margin: 0 auto 10px;
  }
}

@media (min-width: 760px) {
  .index-content .views-row.full {
    width: 100%;
    padding-bottom: 0;
    box-shadow: 4px 4px 15px -4px rgba(0, 0, 0, 0.1);
  }
  .index-content .views-row.full:after {
    content: "";
    display: table;
    clear: both;
  }
  .index-content .views-row.full .field-img {
    height: 200px;
    width: 265px;
    margin-bottom: 0;
    float: left;
    margin-right: 25px;
  }
  .index-content .views-row.full .field-img:before {
    display: none;
  }
  .index-content .views-row.full .title {
    padding: 15px 25px 0 0;
    position: relative;
    margin-bottom: 15px;
    height: 63px;
    overflow: hidden;
  }
  .index-content .views-row.full .title:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background-color: #026e1e;
    width: 50px;
    height: 3px;
    display: block;
  }
  .index-content .views-row.full .txt {
    height: 62px;
    overflow: hidden;
    padding: 0;
    padding-right: 10px;
  }
}

@media (min-width: 760px) and (max-width: 1200px) {
  .index-content .views-row.full .txt {
    padding-right: 30px;
  }
}

.index-content .views-row .new-post {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  background-color: #ffa800;
  padding: 5px 8px 4px;
}

.index-content .views-row .field-img {
  width: 100%;
  height: 170px;
  background-size: cover;
  background-position: left top;
  position: relative;
  margin-bottom: 20px;
}

.index-content .views-row .field-img:before {
  position: absolute;
  bottom: -3px;
  left: 26px;
  content: '';
  background-color: #026e1e;
  width: 50px;
  height: 3px;
  display: block;
}

.index-content .views-row .title {
  font-size: 18px;
  line-height: 24px;
  height: 72px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 0 30px;
}

@media (max-width: 760px) {
  .index-content .views-row .title {
    padding: 0 15px;
  }
}

.index-content .views-row .txt {
  font-size: 14px;
  color: #787878;
  line-height: 22px;
  padding: 0 30px;
  margin-bottom: 15px;
  height: 130px;
  overflow: hidden;
}

@media (max-width: 760px) {
  .index-content .views-row .txt {
    padding: 0 15px;
  }
}

.index-content .views-row .bottom {
  padding: 0 30px;
  height: 30px;
}

.index-content .views-row .bottom:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 760px) {
  .index-content .views-row .bottom {
    padding: 0 15px;
  }
}

.index-content .views-row .more {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  background-position: 80px center;
  float: left;
}

.index-content .views-row .share {
  display: inline-block;
  float: right;
}

.index-content .views-row .share-block {
  display: none;
  float: right;
  position: relative;
  top: 2px;
}

.post-template-default .content-content .info-single {
  display: flex;
  align-items: center;
}

.post-template-default .content-content .info-single .date {
  font-size: 14px;
  color: #9b9b9b;
}

.post-template-default .content-content .info-single .count-views {
  font-size: 14px;
  color: #9b9b9b;
  margin-left: auto;
  margin-right: 20px;
  background: url(../images/views.png) no-repeat left center;
  padding-left: 30px;
}

.post-template-default .content-content .info-single .share-block-wrap {
  height: 30px;
}

.post-template-default .content-content .info-single .share-block-wrap .share {
  display: inline-block;
}

.post-template-default .content-content .info-single .share-block-wrap .share-block {
  display: none;
}

.post-template-default .content-content .single-img {
  margin-bottom: 35px;
}

.post-template-default .content-content .single-img img {
  margin: 0 auto;
}

.post-template-default .content-content p {
  margin-bottom: 15px;
  font-size: 14px;
  color: #3e3e3e;
  line-height: 24px;
}

.post-template-default .content-content h2 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
}

.post-template-default .content-content .wp-block-image .aligncenter {
  position: relative;
}

.post-template-default .content-content .wp-block-image .aligncenter figcaption {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  font-size: 14px;
  padding: 7px 5px 8px;
  background-color: rgba(0, 0, 0, 0.6);
}

#footer {
  background-color: #363636;
  margin-top: 80px;
  padding: 30px 0;
}

#footer > .container {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 760px) {
  #footer > .container {
    display: block;
  }
}

.footer-first .logo {
  margin-bottom: 40px;
}

@media (max-width: 760px) {
  .footer-first .logo {
    margin-bottom: 20px;
  }
  .footer-first .logo img {
    margin: 0 auto;
  }
}

.footer-first .menu {
  list-style: none;
}

@media (max-width: 760px) {
  .footer-first .menu {
    text-align: center;
    margin-bottom: 20px;
  }
}

.footer-first .menu a {
  color: #cccccc;
  text-decoration: none;
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
}

@media (max-width: 760px) {
  .footer-second {
    margin-bottom: 20px;
  }
}

@media (max-width: 760px) {
  .footer-three {
    width: 250px;
    margin: 0 auto 20px;
  }
}

.footer-bottom {
  margin-top: 50px;
  font-size: 12px;
  color: #cccccc;
  font-weight: 300;
}

@media (max-width: 760px) {
  .footer-bottom {
    margin-top: 0;
  }
}

.footer-bottom .container {
  position: relative;
}

.footer-bottom .copy {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
